import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

type ContactPageProps = {
  location: Location
}

const ContactPage: React.FC<ContactPageProps> = ({ location }) => {
  return (
    <Layout location={location} title="Grease">
      <h1>Contact Me</h1>
      <div style={{ marginTop: "20px" }}>
        <StaticImage
          src="../images/jay.jpg"
          alt="Illustration of Juan Gallardo"
          placeholder="blurred"
          layout="constrained"
          width={300}
          style={{ borderRadius: "10px" }}
        />
      </div>

      <p>If you'd like to get in touch, feel free to reach out to me on X:</p>

      <a
        href="https://x.com/jgallardo2847"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: "inline-block",
          padding: "10px 20px",
          margin: "20px 0",
          backgroundColor: "#222",
          color: "white",
          textDecoration: "none",
          borderRadius: "5px",
          fontWeight: "bold",
        }}
      >
        Contact Me on X
      </a>
    </Layout>
  )
}

export default ContactPage
