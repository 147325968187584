import * as React from "react";
import { Link } from "gatsby";
import Seo from "./seo";

interface LayoutProps {
  location: {
    pathname: string;
  };
  title: string;
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  let header;

  if (isRootPath) {
    header = (
      <>
        <h1 className="main-heading">
          <Link to="/">{title}</Link>
        </h1>
        <nav>
          <ul>
            <li>
              <Link to="/merch">Merch</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/events">Events</Link>
            </li>
          </ul>
        </nav>
      </>
    );
  } else {
    header = (
      <>
        <Link className="header-link-home" to="/">
          {title}
        </Link>
        <nav>
          <ul>
            <li>
              <Link to="/merch">Merch</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <Link to="/events">Events</Link>
            </li>
          </ul>
        </nav>
      </>
    );
  }

  return (
    <>
      <Seo title={title} />
      <div className="global-wrapper" data-is-root-path={isRootPath}>
        <header className="global-header">{header}</header>
        <main>{children}</main>
        <footer>© {new Date().getFullYear()}, Grease blog</footer>
      </div>
    </>
  );
};

export default Layout;