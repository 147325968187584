import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

interface SeoProps {
  description?: string;
  title: string;
  children?: React.ReactNode;
}

const Seo: React.FC<SeoProps> = ({ description, title, children }) => {
  const { site } = useStaticQuery<{
    site: {
      siteMetadata: {
        title: string;
        description: string;
        social: {
          x: string;
        };
      };
    };
  }>(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            social {
              x
            }
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;

  // Construct a full title for SEO purposes
  const fullTitle = defaultTitle ? `${title} | ${defaultTitle}` : title;

  return (
    <>
      {/* Set the title tag */}
      <title>{fullTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta name="x:card" content="summary" />
      <meta name="x:creator" content={site.siteMetadata?.social?.x || ``} />
      <meta name="x:title" content={fullTitle} />
      <meta name="x:description" content={metaDescription} />
      {children}
    </>
  );
};

export default Seo;
